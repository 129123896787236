<template>
    <div class="result_wpr new">
        <div class="actions mt-4" v-if="contacts.total">
            <ul class="left">
                <!-- <li class="edit">
                    <label for="check" class="checkbox">
                        <input type="checkbox" id="check" hidden v-model="selectAll" @click="toggleAll">
                        <span><i class="fas fa-check"></i></span>
                    </label>
                </li> -->
                <!-- <li class="optionDrops sort_list" @click="togglePerPageFilter()" v-click-outside="closePerPageDropdown">
                    {{ params.per_page }} <i  class="fas fa-angle-down"></i>
                    <page-filter v-model="params.per_page" :type="2" :is-dropdown="true" ref="contact-index-per-page-filter" />
                </li> -->
                <li class="check_all">
                    <label for="check" class="checkbox">
                        <input type="checkbox" id="check" hidden v-model="selectAll" @click="toggleAll">
                        <span><i class="fas fa-check"></i></span>
                        <h5>{{ selectedContacts.length ? `${selectedContacts.length} Selected` : `Selected All ${params.per_page}`}}</h5>
                    </label>
                </li>
                <li class="optionDrops contacts-tabs" @click.self="bulkActionDropdown = !bulkActionDropdown;" v-click-outside="closeBulkActionDropdown" v-if="selectedContacts.length">
                    Bulk Action <i class="fas fa-angle-down"></i>
                    <div class="dropdown_wpr megamenu" :class="{ active: bulkActionDropdown }">
                        <ul>
                            <template v-for="(action, a) of bulkActions" :key="a">
                                <li @click="triggerBulkAction(action.action)" :class="{'danger': action.title == 'Bulk Delete'}" v-if="action.hasCan && companyUserCan(action.can.action, action.can.module) && !action.hasSubMenu">
                                    {{ action.title }}
                                </li>
                                <li @click="triggerBulkAction(action.action)" v-if="!action.hasCan && !action.hasSubMenu">
                                    {{ action.title }}
                                </li>
                                <li @click="triggerSubmenu($event)" v-if="action.hasCan && companyUserCan(action.can.action, action.can.module) && action.hasSubMenu">
                                    {{ action.title }} <i class="fas fa-chevron-down"></i>
                                    <div class="sub_dropdown">
                                        <ul>
                                            <template v-for="(submenu, s) in action.submenus" :key="s">
                                                <li @click="triggerBulkAction(submenu.action)" v-if="submenu.hasCan && companyUserCan(submenu.can.action, submenu.can.module)">{{ submenu.title }}</li>
                                                <li @click="triggerBulkAction(submenu.action)" v-if="!submenu.hasCan">{{ submenu.title }}</li>
                                            </template>
                                        </ul>
                                    </div>
                                </li>
                            </template>
                        </ul>
                    </div>
                </li>
            </ul>
            <ul class="right">
                <li class="search_area ml-auto" :class="{'active': searchField}">
                    <input type="text" placeholder="Search" ref="search" @input="isTyping = true" v-model.trim="params.search" />
                    <button class="search_btn" @click="searchField = !searchField;">
                        <i class="fas fa-search"></i>
                    </button>
                </li>
                <!-- <li class="break_line"></li> -->
                <li class="optionDrops sort_list" @click="togglePerPageFilter()" v-click-outside="closePerPageDropdown">
                    Show {{ params.per_page }} <i  class="fas fa-angle-down"></i>
                    <page-filter v-model="params.per_page" :type="2" :is-dropdown="true" ref="contact-index-per-page-filter" />
                </li>
                <li class="filter_btn" @click="mobile_filter = true;">
                    <span></span>
                    <span></span>
                    <span></span>
                </li>
                <!-- <li class="list_info">
                    {{ contacts.from ? contacts.from : 0 }} - {{ contacts.to ? contacts.to : 0 }} of <span>{{ contacts.total ? contacts.total : 0 }}</span>
                </li> -->
            </ul>
        </div>
        <!-- <div><line-loader v-if="loader" /></div> -->
        <div class="contact_loader mt-4 mb-4" v-if="loader"><quote-loader /></div>
        <div class="inner_container">
            <div class="scroll_table">
                <table class="show_header" v-if="!loader && contacts.total" mouseenter="showAction(true)" mouseleave="showAction(false)">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Name</th>
                            <th>Joined</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Segment</th>
                            <th>Last</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody v-if="contacts.total">
                        <tr v-for="(contact, c) in contacts.data" :key="c">
                            <td>
                                <label :for="`tag-contact-${contact.id}`" class="checkbox">
                                    <input type="checkbox" :id="`tag-contact-${contact.id}`" :value="contact.id" v-model="selectedContacts" hidden>
                                    <span><i class="fas fa-check"></i></span>
                                </label>
                            </td>
                            <td>
                                <div class="user_wpr" v-click-outside="closeTemperatureOption">
                                    <div class="user_img">
                                        <img :src="contact.photo" alt="" @click.self="toggleProfile(contact)">
                                        <span :class="`temp ${contact.temperature ? contact.temperature.toLowerCase() : 'warm'}`" @click="temperatureOption($event)">
                                            <ul class="row_option">
                                                <li @click="changeTemperature(contact.id, 'cold')">Cold</li>
                                                <li @click="changeTemperature(contact.id, 'warm')">Warm</li>
                                                <li @click="changeTemperature(contact.id, 'hot')">Hot</li>
                                            </ul>
                                        </span>
                                        <svg class="tag" :class="{ active: contact.starred }" @click="toggleStarred($event, contact.id)" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.47 18.8"><path class="cls-1" d="M13.09,6.25l-.43-1.09c-.35-.88-.79-2-1.17-3a1.64,1.64,0,0,0-3,0c-.38,1-.82,2.1-1.17,3L6.85,6.25s-3.36.29-3.95.37C1.25,6.84,1.09,7.8,1.09,7.8a1.58,1.58,0,0,0,.46,1.6h0l2.47,2,.9.74-.29,1.13c-.24.92-.54,2.1-.8,3.1a1.64,1.64,0,0,0,.62,1.73h0a1.63,1.63,0,0,0,1.83.06h0L9,16.47l1-.63,1,.63,2.7,1.72a1.63,1.63,0,0,0,1.84-.05h0a1.64,1.64,0,0,0,.62-1.73c-.26-1-.56-2.18-.8-3.1L15,12.18l.91-.74,2.47-2h0a1.63,1.63,0,0,0-.94-2.88h0l-3.2-.2Z" transform="translate(-0.26 -0.4)"/></svg>
                                    </div>
                                    <h4 @click.self="toggleProfile(contact)">{{ contact.name }}</h4>
                                </div>
                            </td>
                            <td>{{ moment(contact.date_created).format('ll') }}</td>
                            <td>
                                <div class="email_wpr">
                                    <span class="email_add">{{ contact.email }}</span>
                                    <div class="btn_wpr" v-if="contact.valid_email == 0">
                                        <button class="unconfirm">Invalid Email</button>
                                    </div>
                                    <div class="btn_wpr" v-if="contact.is_confirm == 0">
                                        <button class="unconfirm">Unconfirmed</button>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="email_wpr">
                                    <span class="email_add">{{ contact.number }}</span>
                                    <div class="btn_wpr" v-if="contact.number && contact.valid_sms == 0">
                                        <button class="unconfirm">Invalid Phone</button>
                                    </div>
                                    <div class="btn_wpr" v-else-if="!contact.number">
                                        <button class="unconfirm">Missing Phone</button>
                                    </div>
                                </div>
                            </td>
                            <td>{{ contact.status_title }}</td>
                            <td>{{ contact.last_action ? contact.last_action : 'None'  }}</td>
                            <td>
                                <a v-if="contact.has_client_portal" class="access-portal" target="_blank" :href="contact.client_portal_access_link">
                                    <span v-tooltip="`Log into your clients portal`" position="left"><img src="@/assets/images/key.svg"></span>
                                </a>
                                <a @click="toggleLaunchPad(contact)">
                                    <span v-tooltip="`Open your launchpad and perform actions`" position="left"><img src="@/assets/images/launchpad.svg"></span>
                                </a>
                                <!-- <i class="fas fa-ellipsis-v" @click="toggleLaunchPad(contact)"></i> -->
                            </td>
                        </tr>
                    </tbody>
                    <!-- <tbody v-else>
                        <tr>
                            <td colspan="9" class="px-4 text-center">No Records Found</td>
                        </tr>
                    </tbody> -->
                </table>
            </div>
            <!-- <div class="empty_section" v-if="!loader && !contacts.total">
                <img src="@/assets/images/curve_arrow.svg" alt="" class="arrow">
                <h1>Looks like you don't have any Records yet. Click the play button to get started.</h1>
            </div> -->
            <div class="empty_box mt-4" v-if="!loader && !contacts.total">
                <img src="@/assets/images/empty_state.svg">
                <h4>Looks like you don't have any Records yet. Click the play button to get started.</h4>
            </div>
            <div class="list_footer pt-4" v-if="contacts.total">
                <ul>
                    <!-- <li class="optionDrops sort_list" @click="togglePerPageFilter()" v-click-outside="closePerPageDropdown">
                        Show {{ params.per_page }} <i  class="fas fa-angle-down"></i>
                        <page-filter v-model="params.per_page" :type="2" :is-dropdown="true" ref="contact-index-per-page-filter" />
                    </li> -->
                    <li>{{ contacts.from ? contacts.from : 0 }} - {{ contacts.to ? contacts.to : 0 }} of {{ contacts.total ? contacts.total : 0 }}</li>
                </ul>
            </div>
            <div class="pagination pb-4 mt-4" v-if="contacts.total">
                <pagination v-model="params.page" :pages="contacts.last_page" :range-size="0" @update:modelValue="handlePagination" />
            </div>
        </div>
    </div>
    <div class="header_filter" :class="{'show' : mobile_filter}">
        <button class="close_btn" @click="mobile_filter = false;"><i class="fas fa-times"></i></button>
        <div class="action_area">
            <h3 class="sub_heading2">Filters</h3>
            <div class="search_area">
                <input type="text" placeholder="Search" ref="search" @input="isTyping = true" v-model.trim="params.search" />
                <button class="search_btn" @click="searchField = !searchField;">
                    <i class="fas fa-search"></i>
                </button>
            </div>
            <ul>
                <li class="optionDrops contacts-tabs" @click="bulkActionDropdown2 = !bulkActionDropdown2;" v-click-outside="closeBulkActionDropdown2" v-if="selectedContacts.length">
                    Bulk Action <i class="fas fa-angle-down"></i>
                    <div class="dropdown_wpr megamenu" :class="{ active: bulkActionDropdown2 }">
                        <ul>
                            <template v-for="(action, a) of bulkActions" :key="a">
                                <li @click="triggerBulkAction(action.action)" :class="{'danger': action.title == 'Bulk Delete'}" v-if="action.hasCan && companyUserCan(action.can.action, action.can.module) && !action.hasSubMenu">
                                    {{ action.title }}
                                </li>
                                <li @click="triggerBulkAction(action.action)" v-if="!action.hasCan && !action.hasSubMenu">
                                    {{ action.title }}
                                </li>
                                <li @click.stop="triggerSubmenu($event)" v-if="action.hasCan && companyUserCan(action.can.action, action.can.module) && action.hasSubMenu">
                                    {{ action.title }} <i class="fas fa-chevron-down"></i>
                                    <div class="sub_dropdown">
                                        <ul>
                                            <template v-for="(submenu, s) in action.submenus" :key="s">
                                                <li @click="triggerBulkAction(submenu.action)" v-if="submenu.hasCan && companyUserCan(submenu.can.action, submenu.can.module)">{{ submenu.title }}</li>
                                                <li @click="triggerBulkAction(submenu.action)" v-if="!submenu.hasCan">{{ submenu.title }}</li>
                                            </template>
                                        </ul>
                                    </div>
                                </li>
                            </template>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
    </div>

    <launch-pad v-model="launchPad" :contact="activeContact" />
    <profile-component v-model="profile" :contact="activeContact" :updateSelectedContact="updateSelectedContact" />

    <bulk-action-change-gender v-model="genderToggle" :contacts="selectedContacts" />
    <bulk-action-switch-tab v-model="statusToggle" :contacts="selectedContacts" />
    <bulk-action-country-code v-model="togglePhoneCountryCodePanel" :contacts="selectedContacts" />
    <bulk-action-merge-contact v-model="toggleMergeContactsPanel" :contacts="selectedContacts" :module="module" />
    <bulk-action-move-contact v-model="toggleAssignContactsPanel" :contacts="selectedContacts" />
    <bulk-action-celebrate-birthday v-model="toggleContactsBirthdayPanel" :contacts="selectedContacts" />
    <bulk-action-copy-contact v-model="toggleCopyContactsPanel" :contacts="selectedContacts" />
    <bulk-action-change-member-id v-model="toggleChangeMemberIdPanel" :contacts="selectedContacts" />
    <bulk-action-sms v-model="sendSMSToggle" :contacts="selectedContacts" :contacts-data="contacts.data"  />
    <bulk-action-add-tag v-model="addTagsToggle" :contacts="selectedContacts" />
    <bulk-action-remove-tag v-model="removeTagsToggle" :contacts="selectedContacts" />
    <bulk-action-send-email v-model="sendEmailToggle" :contacts="selectedContacts" :is-confirmation="confirmationEmail" />
    <bulk-action-start-pipeline v-model="toggleAddPipelineContactsPanel" :contacts="selectedContacts" />
    <bulk-action-remove-pipeline v-model="toggleRemovePipelineContactsPanel" :contacts="selectedContacts" />
    <bulk-action-start-sequence v-model="sendSequenceToggle" :contacts="selectedContacts" />
    <bulk-action-stop-sequence v-model="stopSequenceToggle" :contacts="selectedContacts" />
    <bulk-action-start-journey v-model="startJourneyToggle" :contacts="selectedContacts" />
    <bulk-action-terminate-journey v-model="terminateJourneyToggle" :contacts="selectedContacts" />
    <bulk-action-send-playbook v-model="assignPlaybookToggle" :contacts="selectedContacts" />
    <bulk-action-restrict-playbook v-model="restrictPlaybookToggle" :contacts="selectedContacts" />
    <bulk-action-send-template v-model="sendTemplateToggle" :contacts="selectedContacts" />
    <bulk-action-send-form v-model="sendFormToggle" :contacts="selectedContacts" />
    <bulk-action-restrict-form v-model="restrictFormToggle" :contacts="selectedContacts" />
    <bulk-action-send-page v-model="sendPageToggle" :contacts="selectedContacts" />
    <bulk-action-restrict-page v-model="restrictPageToggle" :contacts="selectedContacts" />
    <bulk-action-add-file v-model="addFileToggle" :contacts="selectedContacts" />
    <bulk-action-start-progress-tracking v-model="toggleStartProgressTracking" :contacts="selectedContacts" />
    <bulk-action-stop-progress-tracking v-model="toggleStopProgressTracking" :contacts="selectedContacts" />
    <bulk-action-start-habit-tracking v-model="toggleStartHabitTracking" :contacts="selectedContacts" />
    <bulk-action-stop-habit-tracking v-model="toggleStopHabitTracking" :contacts="selectedContacts" />
    <bulk-action-start-task v-model="toggleStartTask" :contacts="selectedContacts" />
    <bulk-action-stop-task v-model="toggleStopTask" :contacts="selectedContacts" />
    <bulk-action-start-workout v-model="toggleStartWorkout" :contacts="selectedContacts" />
    <bulk-action-stop-workout v-model="toggleStopWorkout" :contacts="selectedContacts" />
    <bulk-action-start-workout-plan v-model="toggleStartWorkoutPlan" :contacts="selectedContacts" />
    <bulk-action-stop-workout-plan v-model="toggleStopWorkoutPlan" :contacts="selectedContacts" />
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
    import {Form } from 'vee-validate';

    const LaunchPad = defineAsyncComponent(() => import('@/pages/contact/components/LaunchPad'))
    const ProfileComponent = defineAsyncComponent(() => import('@/pages/contact/components/Profile'))
    const BulkActionChangeGender = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/contact/ChangeGender'))
    const BulkActionSwitchTab = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/contact/SwitchTab'))
    const BulkActionCountryCode = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/contact/CountryCode'))
    const BulkActionMergeContact = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/contact/MergeContact'))
    const BulkActionMoveContact = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/contact/MoveContact'))
    const BulkActionCelebrateBirthday = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/contact/CelebrateBirthday'))
    const BulkActionCopyContact = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/contact/CopyContact'))
    const BulkActionChangeMemberId = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/contact/ChangeMemberId'))
    const BulkActionSms = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/SendSms'))
    const BulkActionAddTag = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/AddTag'))
    const BulkActionRemoveTag = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/RemoveTag'))
    const BulkActionSendEmail = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/SendEmail'))
    const BulkActionStartPipeline = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/StartPipeline'))
    const BulkActionRemovePipeline = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/RemovePipeline'))
    const BulkActionStartSequence = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/StartSequence'))
    const BulkActionStopSequence = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/StopSequence'))
    const BulkActionStartJourney = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/StartJourney'))
    const BulkActionTerminateJourney = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/TerminateJourney'))
    const BulkActionSendPlaybook = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/SendPlaybook'))
    const BulkActionRestrictPlaybook = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/RestrictPlaybook'))
    const BulkActionSendTemplate = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/SendTemplate'))
    const BulkActionSendForm = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/SendForm'))
    const BulkActionRestrictForm = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/RestrictForm'))
    const BulkActionSendPage = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/SendPage'))
    const BulkActionRestrictPage = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/RestrictPage'))
    const BulkActionAddFile = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/AddFile'))
    const BulkActionStartProgressTracking = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/StartProgressTracking'))
    const BulkActionStopProgressTracking = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/StopProgressTracking'))
    const BulkActionStartHabitTracking = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/StartHabitTracking'))
    const BulkActionStopHabitTracking = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/StopHabitTracking'))
    const BulkActionStartTask = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/StartTask'))
    const BulkActionStopTask = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/StopTask'))
    const BulkActionStartWorkout = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/StartWorkout'))
    const BulkActionStopWorkout = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/StopWorkout'))
    const BulkActionStartWorkoutPlan = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/StartWorkoutPlan'))
    const BulkActionStopWorkoutPlan = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/StopWorkoutPlan'))

    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'
    import Toastr from '@/utils/Toastr'
    import moment from 'moment'

    export default {
        name: 'Contact Index',

        data () {
            return {
                bulkActionDropdown: false,
                bulkActionDropdown2: false,
                params: {
                    page: 1,
                    per_page: 5,
                    type: '',
                    search: '',
                },
                selectAll: false,
                selectedContacts: [],
                isTyping: false,
                activeContact: {},
                launchPad: false,
                profile: false,
                moment,

                genderToggle: false,
                statusToggle: false,
                togglePhoneCountryCodePanel: false,
                toggleMergeContactsPanel: false,
                toggleAssignContactsPanel: false,
                toggleContactsBirthdayPanel: false,
                toggleCopyContactsPanel: false,
                toggleChangeMemberIdPanel: false,
                sendSMSToggle: false,
                addTagsToggle : false,
                removeTagsToggle : false,
                sendEmailToggle : false,
                toggleAddPipelineContactsPanel : false,
                toggleRemovePipelineContactsPanel : false,
                sendSequenceToggle: false,
                stopSequenceToggle: false,
                confirmationEmail: false,
                startJourneyToggle: false,
                terminateJourneyToggle: false,
                assignPlaybookToggle: false,
                restrictPlaybookToggle: false,
                sendTemplateToggle: false,
                sendFormToggle: false,
                restrictFormToggle: false,
                sendPageToggle: false,
                restrictPageToggle: false,
                addFileToggle: false,
                toggleStartProgressTracking: false,
                toggleStopProgressTracking: false,
                toggleStartHabitTracking: false,
                toggleStopHabitTracking: false,
                toggleStartTask: false,
                toggleStopTask: false,
                showContent: false,
                searchField: false,
                toggleStartWorkout: false,
                toggleStopWorkout: false,
                toggleStartWorkoutPlan:false,
                toggleStopWorkoutPlan:false,
                mobile_filter: false,
            }
        },

        props: {
            contacts: Object,
            loader: Boolean,
            getContacts: Function,
            contactWatcher: Number,
            module: String,
            deletedThrough: String,
        },

        components: {
            LaunchPad,
            ProfileComponent,
            BulkActionChangeGender,
            BulkActionSwitchTab,
            BulkActionCountryCode,
            BulkActionMergeContact,
            BulkActionMoveContact,
            BulkActionCelebrateBirthday,
            BulkActionCopyContact,
            BulkActionChangeMemberId,
            BulkActionSms,
            BulkActionAddTag,
            BulkActionRemoveTag,
            BulkActionSendEmail,
            BulkActionStartPipeline,
            BulkActionRemovePipeline,
            BulkActionStartSequence,
            BulkActionStopSequence,
            BulkActionStartJourney,
            BulkActionTerminateJourney,
            BulkActionSendPlaybook,
            BulkActionRestrictPlaybook,
            BulkActionSendTemplate,
            BulkActionSendForm,
            BulkActionRestrictForm,
            BulkActionSendPage,
            BulkActionRestrictPage,
            BulkActionStartProgressTracking,
            BulkActionStopProgressTracking,
            BulkActionAddFile,
            BulkActionStartHabitTracking,
            BulkActionStopHabitTracking,
            BulkActionStartTask,
            BulkActionStopTask,
            BulkActionStartWorkout,
            BulkActionStopWorkout,
            BulkActionStartWorkoutPlan,
            BulkActionStopWorkoutPlan,
        },

        watch: {
            'params.per_page' (val) {
                const vm = this;

                vm.params.page     = 1;
                vm.params.per_page = val;

                vm.getContacts(vm.params);
            },

            'params.search' (val) {
                const vm = this;

                setTimeout(() => {
                    vm.isTyping = false;
                }, 1500);
            },

            isTyping (val) {
                const vm = this;

                if (!val) {
                    if (vm.params.search != null) {
                        if (vm.params.search.length >= 2) {
                            vm.params.page = 1;
                            vm.getContacts(vm.params);
                        }
                        if (vm.params.search.length === 0) {
                            vm.params.page = 1;
                            vm.getContacts(vm.params);
                        }
                    }
                }
            },

            bulkActionWatcher (val) {
                const vm = this;

                vm.selectAll        = false;
                vm.selectedContacts = [];
            },

            contactWatcher (val) {
                const vm = this;

                vm.getContacts(vm.params);
            }
        },

        computed: {
            ...mapState({
                bulkActions: state => state.contactModule.bulkActions,
                bulkActionWatcher: state => state.contactModule.bulkActionWatcher,
            }),

            ...mapGetters({
                companyUserCan: 'authModule/COMPANY_USER_CAN',
            })
        },

        mounted () {
            const vm = this;

            vm.getContacts(vm.params);
        },

        methods:{
            ...mapActions({
                bulkDelete: 'contactModule/bulkDelete',
                disableConfirmation: 'contactModule/disableConfirmation',
                validateEmails: 'contactModule/validateEmails',
                markContactAsStarred: 'contactModule/markContactAsStarred',
                updateTemperature: 'contactModule/updateTemperature',
                enableDisableDeliveryStatus: 'contactModule/enableDisableDeliveryStatus',
            }),

            ...mapMutations({
                resetTimezoneByCountry: 'commonModule/SET_TIMEZONES_BY_COUNTRY',
            }),

            closePerPageDropdown () {
                const vm = this;

                const filter = vm.$refs['contact-index-per-page-filter'];

                if (filter) {
                    filter.dropdown = false;
                }
            },

            closeBulkActionDropdown () {
                const vm = this;
                const allParentElement  = document.querySelectorAll('.result_wpr .contacts-tabs .megamenu ul li');

                vm.bulkActionDropdown = false;
                for(let i = 0; i < allParentElement.length; i++){
                    allParentElement[i].classList.remove('show');
                }
            },
            closeBulkActionDropdown2 () {
                const vm = this;
                const allParentElement  = document.querySelectorAll('.header_filter .contacts-tabs .megamenu ul li');

                vm.bulkActionDropdown2 = false;
                for(let i = 0; i < allParentElement.length; i++){
                    allParentElement[i].classList.remove('show');
                }
            },

            handlePagination (page) {
                const vm = this;

                vm.params.page = page;
                vm.getContacts(vm.params);
            },

            togglePerPageFilter () {
                const vm = this;
                vm.bulkActionDropdown = false;

                const filter = vm.$refs['contact-index-per-page-filter'];

                if (filter) {
                    filter.dropdown = !filter.dropdown;
                }
            },

            toggleAll () {
                const vm = this;

                if (!vm.selectAll) {
                    vm.selectedContacts = vm.filterIndexValue(vm.contacts.data);
                } else {
                    vm.selectedContacts = [];
                }
            },

            filterIndexValue (object, index = 'id') {
                const records = [];

                object.forEach((obj) => { records.push(obj[index]) });

                return records;
            },

            triggerBulkAction (action) {
                const vm = this;

                if (vm.selectedContacts.length) {
                    if (action == 'deleteContactsPrompt') {
                        const options = Helper.swalConfirmOptions('Are you sure?', 'This action is permanent. You will not be able to recover these contacts', 'Delete')
                                  options.preConfirm = function() { return vm.bulkDelete({ contacts: vm.selectedContacts , deleted_through: vm.deletedThrough }); };

                        Swal.fire(options);
                    } else if(action == 'toggleMergeContactsPanel') {
                        if( vm.selectedContacts.length < 2 || vm.selectedContacts.length > 3) {
                            const options = Helper.swalWarningOptions('Oops!', 'Please select a minimum of 2 or maximum of 3 contacts');

                            Swal.fire(options)
                        } else {
                            vm[action] = true;
                        }
                    } else if (action == 'toggleDeactivateBirthdayPanel') {
                        const options = Helper.swalConfirmOptions('', "You wish to deactivate these birthday celebration.")
                        options.preConfirm = function () {
                            const params = { status: 0, contact_id: vm.selectedContacts.join(','), type: 'birthday' };
                            return vm.enableDisableDeliveryStatus(params).then((result) => {
                                if (result) {
                                    vm.selectAll = false;
                                    vm.selectedContacts = [];
                                }
                            });
                        };

                        Swal.fire(options);
                    } else if (action == 'disableSMSSendingPrompt') {
                        const options = Helper.swalConfirmOptions('Are You Sure?', "You want to disable the SMS sending for the selected contacts.")
                        options.preConfirm = function() { return vm.handleDelivaryStatus('sms', 0) };

                        Swal.fire(options);
                    } else if (action == 'enableSMSSendingPrompt') {
                        const options = Helper.swalConfirmOptions('Are You Sure?', "You want to enable the SMS sending for the selected contacts.")
                        options.preConfirm = function() { return vm.handleDelivaryStatus('sms', 1) };

                        Swal.fire(options);
                    } else if (action == 'disableEmailSendingPrompt') {
                        const options = Helper.swalConfirmOptions('Are You Sure?', "You want to disable the E-mail sending for the selected contacts.")
                        options.preConfirm = function() { return vm.handleDelivaryStatus('email', 0) };

                        Swal.fire(options);
                    } else if (action == 'enableEmailSendingPrompt') {
                        const options = Helper.swalConfirmOptions('Are You Sure?', "You want to enable the E-mail sending for the selected contacts.")
                        options.preConfirm = function() { return vm.handleDelivaryStatus('email', 1) };

                        Swal.fire(options);
                    } else if (action == 'disableConfirmationPrompt') {
                        const options = Helper.swalConfirmOptions('Are You Sure?', "You want to disable the E-mail confirmation for the selected contacts.")
                        options.preConfirm = function() { return vm.disableConfirmation({ contacts: vm.selectedContacts }) };

                        Swal.fire(options);
                    } else if (action == 'requestConfirmationToggle') {
                        vm.confirmationEmail  = true;
                        vm.sendEmailToggle    = true;
                    } else if (action == 'validateEmailPrompt') {
                        const options = Helper.swalConfirmOptions('Are You Sure?', "You want to Validate E-mail for the selected contacts.")
                        options.preConfirm = function() { return vm.validateEmails({ contacts: vm.selectedContacts }) };

                        Swal.fire(options);
                    } else if (action == 'enableBroadcasts') {
                        const options = Helper.swalConfirmOptions('Are You Sure?', "You want to enable broadcast for the selected contacts.")
                        options.preConfirm = function() { return vm.handleDelivaryStatus('broadcast', 1) };

                        Swal.fire(options);
                    } else if (action == 'disableBroadcasts') {
                        const options = Helper.swalConfirmOptions('Are You Sure?', "You want to disable broadcast for the selected contacts.")
                        options.preConfirm = function() { return vm.handleDelivaryStatus('broadcast', 0) };

                        Swal.fire(options);
                    } else {
                        vm[action] = true;
                    }
                } else {
                    Toastr.error('Please select at least one contact to use bulk action!');
                }
            },
            triggerSubmenu(ev){
                const parentElement = ev.currentTarget;
                const allParentElement  = document.querySelectorAll('.contacts-tabs .megamenu ul li');

                if (parentElement.classList.contains('show')) {
                    parentElement.classList.remove('show');
                } else {
                    for(let i = 0; i < allParentElement.length; i++){
                        allParentElement[i].classList.remove('show');
                    }

                    parentElement.classList.add('show');
                }
            },

            changeTemperature (contact_id, temperature) {
                const vm = this;

                const option = Helper.swalConfirmOptions('Are You Sure?', `You want to change temperature to ${temperature}.`)
                option.preConfirm = function() {
                    return vm.updateTemperature({ contact_id, temperature}).then((result) => {
                        if (result) {
                            vm.getContacts(vm.params);
                        }
                    })
                };

                Swal.fire(option);
            },

            toggleStarred (e, contact_id) {
                e.stopPropagation();

                const vm    = this;
                let element = e.currentTarget;
                let starred = 1;

                if (element.classList.contains('active')) {
                    element.classList.remove('active');
                    starred = 0;
                } else {
                    element.classList.add('active');
                    starred = 1;
                }

                vm.markContactAsStarred({ starred, contact_id }).then((result) => {
                    if (!result) {
                        if (starred == 1) {
                            element.classList.remove('active');
                        } else {
                            element.classList.add('active');
                        }
                    }
                });
            },

            toggleLaunchPad (contact) {
                const vm = this;

                vm.activeContact = contact;
                vm.launchPad = true;
            },

            toggleProfile (contact) {
                const vm = this;

                vm.resetTimezoneByCountry([]);
                vm.activeContact = contact;
                vm.profile = true;
            },

            temperatureOption (e) {
                e.stopPropagation();
                let el = e.currentTarget.querySelector('.row_option');
                let allEl = document.querySelectorAll('table .row_option');

                if (el.classList.contains('active')) {
                    el.classList.remove('active');
                } else {
                    for(let i = 0; i < allEl.length; i++){
                        allEl[i].classList.remove('active');
                    }
                    el.classList.add('active');
                }
            },

            closeTemperatureOption () {
                let allEl = document.querySelectorAll('table .row_option');

                for(let i = 0; i < allEl.length; i++){
                    allEl[i].classList.remove('active');
                }
            },

            toggleModalOpen (val) {
                if (val) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            handleDelivaryStatus (type, status) {
                const vm = this;

                const params = {
                    contacts: vm.selectedContacts,
                    type: type,
                    status: status,
                }

                return vm.enableDisableDeliveryStatus(params).then((result) => {
                    vm.selectAll = false;
                    vm.selectedContacts = [];
                });
            },

            updateSelectedContact (contact) {
                const vm = this;

                vm.getContacts(vm.params);
                vm.activeContact = contact;
            },

            // showAction (ev) {
            //     const vm = this;
            // 
            //     if (ev) {
            //         vm.showContent = true;
            //     } else {
            //         vm.showContent = false;
            //     }
            // },
        }
    }
</script>

<style scoped>
    .result_wpr.new .actions{
        max-width: 1300px;
        width: 100%;
        margin: 20px auto 10px auto;
    }
    .contact_loader{
        max-width: 1300px;
        margin: 0 auto;
    }
    .inner_container{
        max-width: 1300px;
        margin: 0 auto;
    }
    .acton_header .left_section {
        flex: 1;
    }

    .acton_header .left_section button.edit {
        padding: 0 12px;
        border: 1px solid #6da9fd;
        height: 43px;
    }

    .acton_header .right_section button {
        padding: 0 23px;
        border: 1px solid #2F7FED;
        border-radius: 4px;
        height: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: transparent;
        font-size: 15px;
        line-height: 20px;
        text-transform: uppercase;
        font-weight: 600;
        color: #2F7FED;
        cursor: pointer;
    }

    table button {
        padding: 3px 10px;
        font-size: 11px;
        line-height: 13px;
        font-weight: 400;
        background: #f2a31e;
        border-radius: 10px;
        color: #fff;
        margin-left: 7px;
    }

    table button.success {
        background: #00aa14;
    }

    table button.failed {
        background: #f04438;
    }

    .result_wpr.small .actions>ul {
        height: 35px;
    }

    .result_wpr.small .actions>ul li,
    .result_wpr.small .actions>ul li input {
        padding: 7px 10px;
        font-size: 14px !important;
    }

    .result_wpr.small .actions>ul li .dropdown_wpr {
        width: 100%;
    }

    .result_wpr.small table td {
        padding: 12px;
        font-size: 13px;
        line-height: 16px;
    }

    .result_wpr.small table th {
        padding: 7px 12px;
        font-size: 13px;
        line-height: 16px;
    }

    .result_wpr.small table td .user_img,
    .result_wpr.small table td .user_img img {
        width: 30px;
        height: 30px;
    }

    .result_wpr.small table td h4 {
        font-size: 13px;
        line-height: 16px;
        color: #2f7eed;
        font-weight: 500;
    }

    .result_wpr table.standard td:first-child,
    .result_wpr table.standard th:first-child {
        width: auto;
        text-align: left;
    }

    .row_option li a {
        color: inherit;
        text-decoration: none;
    }

    .action_list {
        display: flex;
        flex-wrap: wrap;
    }

    .action_list li {
        margin-right: 5px;
        cursor: pointer;
    }

    .action_list li i.fa-envelope {
        color: #f2a31d;
    }

    .action_list li i.fa-comment {
        color: #2f7eed;
    }

    .action_list li i.fa-trash-alt {
        color: #eb1414;
    }

    .action_list li i.fa-search-plus {
        color: #32373b;
    }

    /* .filter_grp .multiselect-group .multiselect-group-label .id-tags {
        background: linear-gradient(90deg,#2f7fed,#2563b9);
        color: #fff;
    }

    .filter_grp .multiselect-group .multiselect-group-label .trigger-tags {
        background: linear-gradient(90deg,#75d218,#75e212);
        color: #fff;
    }

    .filter_grp .multiselect-group .multiselect-group-label .system-tags {
        background: linear-gradient(to right, #FF9041, #F5AD2E);
        color: #FFF;
    } */
    .empty_section{
        background: #fff;
        border-radius: 10px;
        height: 300px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 20px 0;
    }
    .result_wpr table{
        min-width:1000px;
    }
    .header_filter{
        position: fixed;
        top: 0;
        bottom: 0;
        background: #fff;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
        width: 250px;
        overflow-y: auto;
        right: -250px;
        z-index: 12;
        transition: all 0.3s ease-in-out;
        text-align: left;
    }
    .header_filter.show{
        right: 0;
    }
    .header_filter .close_btn{
        position: absolute;
        right: 20px;
        top: 20px;
        width: 20px;
        height: 20px;
        font-size: 10px;
        border-radius: 50%;
        background: #eaeaea;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #5a5a5a;
        cursor: pointer;
    }
    .header_filter .top_area{
        padding: 20px;
    }
    .header_filter .top_area h3{
        font-size: 17px;
        line-height: 22px;
        font-weight: 500;
        color: #121525;
        margin-bottom: 15px;
    }
    .header_filter .search_area {
        padding: 0 31px 0 0;
        border-radius: 17px;
        background: #f5f5f5;
        position: relative;
        margin: 15px 0;
    }
    .header_filter .search_area input[type=text] {
        font-size: 11px;
        height: 30px;
        width: 100%;
        background: transparent;
        padding: 0 0 0 15px;
        transition: all 0.3s ease-in-out;
    }
    .header_filter .search_area .search_btn {
        position: absolute;
        right: 0;
        top: 0;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        font-size: 11px;
        color: #121525;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .header_filter .top_area ul {
        list-style-type: none;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-top: 5px;
    }
    .header_filter .top_area ul li:not(.switch_btn) {
        height: 30px;
        width: 30px;
        background: #2f7eed;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.02);
        margin-right: 6px;
    }
    .header_filter .top_area ul li img {
        max-width: 16px;
        height: auto;
        filter: brightness(100);
    }
    .header_filter .action_area{
        padding: 20px;
        border-top: 1px solid #e9e9e9;
    }
    .header_filter .action_area ul li .checkbox h5 {
        font-size: 13px;
        line-height: 16px;
        font-weight: 400;
        color: #121525;
        margin: 5px 0;
    }
    .header_filter .action_area > ul > li {
        font-size: 13px;
        line-height: 18px;
        font-weight: 400;
        color: #5a5a5a;
        text-align: left;
        /* background: #f5f5f5; */
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;
        margin-bottom: 10px;
    }
    .header_filter .action_area > ul > li.optionDrops{
        padding: 7px 15px;
        border: 1px solid #e9e9e9;
        border-radius: 20px;
    }
    .header_filter .action_area > ul > li > i{
        margin-left: auto;
    }
    .header_filter .action_area .dropdown_wpr:not(.megamenu) ul{
        max-height: 250px;
        overflow-y: auto;
    }
    .header_filter .action_area .dropdown_wpr ul li{
        border: 0;
    }
    .header_filter .action_area .dropdown_wpr ul li.active{
        background: #f5f5f5;
    }
    .header_filter .action_area .dropdown_wpr.megamenu{
        width: 100%;
        left: 0;
    }
    .header_filter .action_area .dropdown_wpr.megamenu > ul > li {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    .result_wpr.new .actions > ul li.filter_btn{
        background: #fff;
        width: 30px;
        height: 30px;
        border-radius: 3px;
        padding: 4px 6px;
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        cursor: pointer;
    }
    .filter_btn span{
        height: 2px;
        width: 100%;
        border-radius: 2px;
        background: #2f7eed;
        margin: 2px 0;
    }
    .filter_btn span:nth-child(2){
        width: 60%;
    }
    @media(max-width: 767px){
        .acton_header .left_section .search_area{
            display: none;
        }
        .result_wpr.new .actions > ul > li:not(.check_all, .sort_list, .filter_btn){
            display: none;
        }
        .result_wpr.new .actions > ul li.filter_btn{
            display: flex;
        }
    }
    @media(min-width: 768px){
        .header_filter{
            display: none;
        }
    }
</style>
